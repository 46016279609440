<template>
  <div>
    <!-- 额外费用 -->
    <el-dialog :visible="extraDialog.show" @close="extraDialog.show = false"
               title="额外费用" width="360px" class="dialog-vertical extra-dialog" append-to-body>
      <el-form class="extra-detail" size="small" label-width="85px" label-position="top" @submit.native.prevent>
        <template v-for="(item, index) in extraDialog.data.filter(d => d.type !== 'input' && d.is_user_info !== 1)">
          <el-form-item :key="item.name" :label="item.name">
            <template v-if="item.type === 'select' && item.options && item.options.find(op => op.text === item.text_value)">
              {{item.text_value}}
              (需支付 {{item.options.find(op => op.text === item.text_value).pay_type ? '' : '￥'}}{{item.options.find(op => op.text === item.text_value).pay_amount}}
              {{item.options.find(op => op.text === item.text_value).pay_type ? '积分' : ''}})
            </template>
            <template v-else-if="item.type === 'multi-select' && item.text_value && item.text_value.length">
            <span v-for="ud in item.text_value">
                <template v-if="item.options.find(op => op.text === ud)">
                  {{ud}}
                  (需支付 {{item.options.find(op => op.text === ud).pay_type ? '' : '￥'}}{{item.options.find(op => op.text === ud).pay_amount}}
                  {{item.options.find(op => op.text === ud).pay_type ? '积分' : ''}})<br/>
                </template>
            </span>
            </template>
            <span v-else-if="!item.text_value || !item.text_value.length">{{'' | placeholder}}</span>
          </el-form-item>
          <el-divider />
        </template>

      </el-form>
      <el-form size="small" >
        <el-form-item label="合计：">{{formatExtraSum(getExtraSum(extraDialog.data))}}</el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center">
        <el-button style="text-align: center;" size="small" @click="extraDialog.show = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ExtraDialog",
  props: ['extraDialog'],
  methods: {
    getExtraSum(formList = []) {
      formList = (formList || []).filter(f => f.is_user_info !== 1)
      const total = {
        money: 0,
        integral: 0
      }
      formList.forEach(f => {
        const user_data = f.text_value
        const is_select = f.type !== 'input'
        const is_arr = is_select && user_data && typeof user_data !== 'string'
        if (!is_arr) {
          if (is_select) {
            if (f.options !== undefined) f.options.forEach(ff => {
              if (user_data === ff.text) {
                /* pay_type 支付方式：0-金额、1-积分 */
                if (ff.pay_type) total.integral += ff.pay_amount
                else total.money += ff.pay_amount
              }
            })
          } else {
            /* 输入框类型不需要付费 */
          }
        } else {
          if (f.options !== undefined) f.options.forEach(ff => {
            if (user_data.includes(ff.text)) {
              /* pay_type 支付方式：0-金额、1-积分 */
              if (ff.pay_type) total.integral += ff.pay_amount
              else total.money += ff.pay_amount
            }
          })
        }
      })
      return total
    },
    formatExtraSum(total) {
      const money = `￥${total.money}`
      const integral = `${total.integral}积分`
      const arr = []
      if (total.money) arr.push(money)
      if (total.integral) arr.push(integral)
      return arr.join(' + ')
    },
  },
}
</script>

<style lang="scss">
.extra-dialog .extra-detail .el-form-item__content {
  color: rgba(128,128,128,1);
  font-size: 12px;
}
</style>
