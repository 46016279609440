import { render, staticRenderFns } from "./RemarkDialog.vue?vue&type=template&id=b8a924d8&scoped=true&"
import script from "./RemarkDialog.vue?vue&type=script&lang=js&"
export * from "./RemarkDialog.vue?vue&type=script&lang=js&"
import style0 from "./RemarkDialog.vue?vue&type=style&index=0&id=b8a924d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8a924d8",
  null
  
)

export default component.exports