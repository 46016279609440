var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-steps-area",style:("width: 100%;")},[_c('div',{staticClass:"m-steps"},[_vm._l((_vm.totalSteps),function(n){return [(_vm.stepsLabel[n - 1].show)?_c('div',{class:[
          'm-steps-item',
          {
            finished: _vm.current > n,
            process: _vm.current === n && n !== _vm.totalSteps,
            'last-process': _vm.current === _vm.totalSteps && n === _vm.totalSteps,
            'middle-wait': _vm.current < n && n !== _vm.totalSteps,
            'last-wait': _vm.current < n && n === _vm.totalSteps,
          } ]},[_c('div',{staticClass:"m-info-wrap"},[_c('div',{staticClass:"m-steps-icon"},[(_vm.current <= n)?_c('span',{staticClass:"u-icon"}):_vm._e()]),_c('div',{staticClass:"m-steps-content"},[_c('div',{staticClass:"u-steps-title"},[_vm._v(_vm._s(_vm.stepsLabel[n - 1].name))])])])]):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }