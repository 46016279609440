<template>
  <div class="content-box">
    <el-dialog
      title=""
      :visible="is_show"
      width="700px"
      class="dialog-vertical"
      append-to-body
      @close="close()"
      :show-close="false"
      center
      @open="openDialogFn"
    >
      <!-- 头部 -->
      <template #title>
        <Steps
          v-if="is_show"
          v-model="step"
          :totalSteps="totalSteps"
          :stepsLabel="stepsLabel"
          :totalWidth="650"
          :descMaxWidth="100"
          @change="onChange"
        />
      </template>

      <el-form
        v-if="is_show"
        v-loading="loading"
        size="medium"
        class="medium-form"
        :model="form"
        :rules="rules"
        label-width="65px"
        ref="form"
        @submit.native.prevent="() => {}"
      >
        <div v-if="!is_non_cycuser">
          <!-- 选择会员 -->

          <SelectMember
            v-show="step == 2"
            @select="memberSelect"
          ></SelectMember>
        </div>
        <div v-else>
          <!-- 选择非会员 -->
          <SelectNoCycUser
            v-show="step == 2"
            @select="memberSelect"
          ></SelectNoCycUser>
        </div>

        <!-- 填写信息 -->

        <CustomForm
          @hiddenForm="hiddenForm"
          v-show="step == 3"
          ref="CustomForm"
        ></CustomForm>

        <!-- 完成 -->
        <div v-show="step == 5">
          <el-result
            icon="success"
            title="报名成功"
            subTitle="您已成功报名该活动!"
          >
          </el-result>
        </div>
      </el-form>

      <span class="footer" slot="footer">
        <el-button @click="close()" size="small">取消</el-button>
        <el-button
          v-if="step !== startStep && step !== 5"
          type="primary"
          :loading="loading"
          size="small"
          @click="preStep"
          >上一步</el-button
        >
        <el-button
          v-if="step !== 5"
          type="primary"
          :loading="loading"
          size="small"
          @click="nextStep"
          >下一步</el-button
        >
        <el-button
          v-else="step === 5"
          type="primary"
          :loading="loading"
          size="small"
          @click="close"
          >完成</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import SelectMember from './SelectMember.vue'
import SelectNoCycUser from './SelectNoCycUser.vue'
import CustomForm from './CustomForm.vue'
import Steps from './Steps.vue'
import { saveFeeJoinUser, saveJoinUser } from '../../../api/sign-up-list/list'
import { JoinSettings } from '../../../api/activity-list/detail'

export default {
  name: 'SignUpMemberDialog',
  model: {
    prop: 'open',
    event: 'changeOpen',
  },
  props: {
    // 弹窗配置
    open: {
      type: Boolean,
      detault: false,
    },
    // 是否非会员
    is_non_cycuser: {
      type: Number,
      required: true,
    },
  },
  components: { Steps, SelectMember, CustomForm, SelectNoCycUser },
  created() {},
  computed: {
    // 显示框
    is_show: {
      get() {
        return this.$props.open
      },
      set(val) {
        this.$emit('changeOpen', val)
      },
    },
  },
  watch: {},

  data() {
    return {
      form: {
        user_ids: '',
        join_form: [],
      },
      // 选择的数据
      selectIds: [],
      rules: {},
      // 步骤条
      step: 2,
      // 实际开始的步骤条位置
      startStep: 2,
      totalSteps: 5,
      needForm: true,
      mode: 'free', // 支付模式 ， pay, nopay, free
      stepsLabel: [
        { name: '类型选择', show: false },
        { name: '选择会员', show: true },
        { name: '填写信息', show: true },
        { name: '支付费用', show: false },
        { name: '完成', show: true },
      ],
      loading: false,
    }
  },
  mounted() {},
  methods: {
    openDialogFn() {
      console.log(this.is_non_cycuser)
      if (this.$props.is_non_cycuser) {
        this.stepsLabel[1].name = '选择游客'
      } else {
        this.stepsLabel[1].name = '选择会员'
      }
      this.getActivityType()
    },
    close() {
      this.step = this.startStep
      this.form = {
        user_ids: '',
        join_form: [],
      }
      this.selectIds = []
      this.is_show = false
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.close()
        } else {
          console.log('error submit!!')
          return false
        }
      })
      this.close()
    },
    // 上一步
    preStep() {
      //  如果上一步隐藏了，再上一步
      let index = this.step
      while (this.stepsLabel[index - 2] && !this.stepsLabel[index - 2].show) {
        console.log(index, 22)
        index--
      }
      console.log(index)
      if (index < this.startStep) {
        // 不能再上一步了
        return
      }
      if (index === this.step) {
        this.step--
      } else {
        this.step = index - 1
      }
    },
    // 下一步
    nextStep() {
      if (this.step == 2) {
        if (!this.selectIds.length) {
          this.$message.info('请选择数据！')
          return
        }
      }
      // 填写完信息，或者不需要填写信息
      if (this.step == 3 || (this.step == 2 && !this.needForm)) {
        // 校验数据拿到值
        this.$refs.CustomForm.getFormData((data) => {
          this.form.join_form = data
          console.log(data)
          if (this.mode === 'free') {
            this.addUserJoin()
          }
        })
      } else {
        //  如果下一步隐藏了，再下一步
        let index = this.step
        while (this.stepsLabel[index] && !this.stepsLabel[index].show) {
          console.log(index)
          index++
        }
        if (index === this.step) {
          this.step++
        } else {
          this.step = index
        }
      }
    },
    onChange(index) {
      // 父组件获取切换后的选中步骤
      console.log('current:', index)
    },
    // 选择会员
    memberSelect(arr) {
      this.selectIds = arr
    },
    // 添加报名
    addUserJoin() {
      if (this.mode === 'free') {
        let task = []
        this.selectIds.forEach((ele) => {
          task.push(
            saveJoinUser({
              activity_id: this.$route.params.id,
              user_id: ele,
              is_non_cycuser: this.is_non_cycuser,
              join_form: JSON.stringify(this.form.join_form),
              save_type: 0,
            })
          )
        })
        if (task.length) {
          Promise.all(task).then((res) => {
            this.$message.success('报名成功')
            // 跳转到完成
            this.step = 5
            this.$emit('update')
          })
        }
      }
    },
    // 获取活动类型，是不是免费
    getActivityType() {
      this.loading = true
      JoinSettings({ id: this.$route.params.id })
        .then((res) => {
          // if (res.join_type === 0) {
          this.mode = 'free'
          this.startStep = 2
          this.step = this.startStep
          // } else if (res.join_type === 1 || res.join_type === 2) {
          // 付费或者保证金报名
          // this.mode = 'pay'
          // this.startStep = 1
          // this.step = this.startStep;

          // }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 不需要填写表单信息,需要隐藏填写步骤
    hiddenForm() {
      this.needForm = false
      this.stepsLabel[2].show = false
    },
  },
}
</script>
<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
