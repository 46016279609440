/*
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-08-25 11:24:38
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-23 14:33:42
 */
import api from "@/base/utils/request";
import fetchFile from "@/base/utils/download";
import filesUpload from "@/base/utils/upload4";

// 获取详情
export const getActivityUser = data => {
  return api({
    url: "/admin/cyc_activity/ActivityUser/index",
    method: "post",
    data
  });
};

// 获取活动报名相关数量
export const getActivityUserSum = data => {
  return api({
    url: "/admin/cyc_activity/activity_user/sum",
    method: "post",
    data,
    notCancel: true
  });
};

//添加报名用户
export const addJoinUser = data => {
  return api({
    url: "/admin/cyc_activity/activity_user/addJoinUser",
    method: "post",
    data
  });
};

// 移除报名用户
export const delJoinUser = data => {
  return api({
    url: "/admin/cyc_activity/activity_user/del",
    method: "post",
    data
  });
};

// 签到操作
export const signJoinUser = data => {
  return api({
    url: "/admin/cyc_activity/activity_user/sign",
    method: "post",
    data
  });
};

// 获取所属架构/职务列表
export const positionLevel = data => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data,
    notCancel: true
  });
};

// 非会员数据列表
export const nonMemberList = data => {
  return api({
    url: "/admin/cyc/noncyc_user/index",
    method: "post",
    data
  });
};

// 非会员报名审核通过
export const check = data => {
  return api({
    url: "/admin/cyc_activity/activity_user/check",
    method: "post",
    data
  });
};

// 非会员报名审核不通过
export const uncheck = data => {
  return api({
    url: "/admin/cyc_activity/activity_user/uncheck",
    method: "post",
    data
  });
};

// 报名名单查看详情
export const joinDetail = data => {
  return api({
    url: "/admin/cyc_activity/ActivityUser/detail",
    method: "post",
    data
  });
};


// 更新备注
export const updateRemark = data => {
  return api({
    url: "/admin/cyc_activity/ActivityUser/updateRemark",
    method: "post",
    data
  });
};


// 定向邀请列表
export const inviteUserList = data => {
  return api({
    url: "/admin/cyc_activity/activity_user/inviteUserList",
    method: "post",
    data
  });
};
// 定向邀请添加
export const addInviteUser = data => {
  return api({
    url: "/admin/cyc_activity/activity_user/addInviteUser",
    method: "post",
    data
  });
};
// 邀请海报小程序码
export const inviteUserPoster = data => {
  return api({
    url: "/admin/cyc_activity/activity_user/inviteUserPoster",
    method: "post",
    data
  });
};
// 临时报名
export const temporarySign = data => {
  return api({
    url: "/admin/cyc_activity/activity_user/temporarySign",
    method: "post",
    data
  });
};
// 退还部分费用
export const refundPart = data => {
  return api({
    url: "/admin/cyc_activity/activity_user/refundPart",
    method: "post",
    data
  });
};
// 退还全部费用
export const refundAll = data => {
  return api({
    url: "/admin/cyc_activity/activity_user/refundAll",
    method: "post",
    data
  });
};
// 添加无需付费的报名
export const saveJoinUser = data => {
  return api({
    url: "/admin/cyc_activity/activity_user/saveJoinUser",
    method: "post",
    data,
    notCancel:true
  });
};
// 添加需付费的报名
export const saveFeeJoinUser = data => {
  return api({
    url: "/admin/cyc_activity/activity_user/saveFeeJoinUser",
    method: "post",
    data
  });
};

// 定向邀请列表导出
export const exportInviteUser = data => {
  return fetchFile({
    url: "/admin/cyc_activity/activity_user/exportInviteUser",
    method: "post",
    data,
    download: true
  });
};
// 获取非会员列表
export const getNonCyc = data => {
  return api({
    url: "/admin/cyc/noncyc_user/index",
    method: "post",
    data
  });
};



// 下载报名名单导入模板
export const signImportXlsx = (data) => {
  console.log(data)
  return fetchFile({
    url: "/admin/cyc_activity/activity_user/joinUsersImportXlsx",
    method: "post",
    download: true,
    data: data,
  });
};

// 导入
export const placeImport = (data, extra) => {
  return filesUpload({
    url: '/admin/cyc_activity/activity_user/importJoinUsers',
    data,
    extra,
    typeArr: ["file"]
  });
}