import { runPromiseByQueue, sliceArrFn } from '@/base/utils/tool'
import { getTemporaryFileUrl } from '@/modules/common/api/media'
import { Message, MessageBox } from 'element-ui'
import { saveAs } from 'file-saver'
import JSZip from 'jszip' // API 文档：http://docs.asprain.cn/jszip/jszip.html#jszip_generate_async
// import dayjs from 'dayjs'

export class exportFilesTool {
  progress = ''
  quit = false
  options = { size: 5, zipFileName: '', onProgress: (percent) => {} }
  sum = 0
  count = 0

  constructor(options = {}) {
    this.options = Object.assign({}, this.options, options)
  }

  /**
   * 开始下载
   * @param list
   * @returns {Promise<unknown>}
   */
  start(list = []) {
    this.sum = list.length

    // return this.exportFiles(list, this.options)
    return new Promise(async (resolve) => {
      for (let i = 0; i < list.length; i++) {
        if (this.quit) break

        const { nickname, files } = list[i]
        await this.exportFiles(files, {
          ...this.options,
          zipFileName: nickname,
        })

        this.count++ // 结束了才加1

        if (this.count === this.sum) resolve()
      }
    })
  }

  /**
   * 终止下载
   */
  stop() {
    this.quit = true
  }

  /**
   * 接收一个文件列表一维数组
   * 分批引导浏览器下载文件
   * @param list
   * @param size
   * @param zipFileName
   * @param onProgress
   * @returns {Promise<unknown>}
   */
  exportFiles(list = [], { size, zipFileName, onProgress }) {
    // const folderName = '附件导出' +  (dayjs().format(`YYYYMMDDHHmmss`) ||'folder')
    const folderName = zipFileName
    const zip = new JSZip() // 利用这个来构造压缩包的内容以提供下载
    const folder = zip.folder(folderName) // 创建文件夹，该文件夹作为解压后得到的根目录文件夹

    return new Promise(async (resolve) => {
      const list_sliced = sliceArrFn(list, size || 5) // 10个文件一组
      let progress = 0,
        downloaded = 0
      const promise_arr_group = list_sliced.map((_list, _i) => {
        const promise_arr = _list.map((item, index) => {
          // console.log('download url =>', _url)
          return () =>
            new Promise(async (resolve) => {
              if (this.quit) {
                resolve(false)
                console.log('用户请求暂停，立刻结束')
                return
              }
              let _url = item.url
              if (item.is_encrypt) {
                const {
                  data: { url },
                } = await getTemporaryFileUrl(item.url)
                _url = url
              }

              // const downloadUrl = _url + (_url.indexOf('?') > -1 ? '&' : '?') + 'response-content-disposition=attachment'; // 补充参数

              fetch(_url, {
                // cors、no-cors和same-origin
                mode: 'cors',
              })
                .then((response) => response.blob())
                .then((data) => {
                  downloaded++
                  progress = ((downloaded * 100) / list.length * (
                      (this.count + 1)
                      / this.sum
                  )).toFixed(2)
                  this.progress = progress
                  // console.log(progress, 'progress')
                  onProgress(progress)

                  // saveAs(data, item.file_name) // 交给浏览器
                  // console.log(item.file_name, 'saveAs after')

                  // 改为放进压缩包
                  // 提示：文件名含斜杠，会被识别为目录哦，所以要特殊处理一下
                  const fileName = item.file_name.replace(/\//g, '_')
                  folder.file(fileName, data) // 向文件夹中新增文件并在文件中写入内容

                  resolve()
                })
                .catch((err) => {
                  console.log(err)
                  resolve(false)
                  Message({
                    message: `${item.file_name} 下载失败！`,
                    type: 'info',
                  })
                })
            })

          // saveAs(_url, item.file_name) // 如果不经过 fetch 转 blob，图片文件会变成预览而不是直接下载哦
        })
        return () =>
          new Promise((resolve) => {
            Promise.all(promise_arr.map((p) => p())).finally(() => {
              resolve()
              console.log('分批结束')
            })
          })
      })

      // await Promise.all(promise_arr_group)
      await runPromiseByQueue(promise_arr_group) // 分组间同步

      zip.generateAsync({ type: 'blob' }).then(function (blob) {
        saveAs(blob, `${folderName}.zip`) // 触发浏览器下载操作
        resolve()
      }) // 打包：在当前的文件夹级别生成一个完整的Zip文件。

      // resolve()
    })
  }
}
