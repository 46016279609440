<template>
  <LayoutFilter
    :onAttachment="onExportFiles"
    :onExport="onExport"
    :onFilter="ok"
    :onReset="resetForm"
    :toggleCollapseEnable="true"
    collapseHeight="120"
  >
    <el-form
      :model="form"
      :rules="rules"
      @submit.native.prevent
      class="filter-from"
      label-width="70px"
      ref="logFilterForm"
      size="medium"
    >
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="用户名称：" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item
          label="会内职务："
          prop="position_ids"
          v-show="filterForm.nav === 'cycuser'"
        >
          <el-cascader
            :options="parentPositionList"
            :props="props"
            placeholder="请选择"
            v-model="filterForm.position_ids"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item
          label="手机号码："
          prop="phone"
          v-show="filterForm.nav !== 'cycuser'"
        >
          <el-input
            placeholder="请输入手机号码"
            v-model="form.phone"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="身份类型："
          prop="user_type"
          v-show="filterForm.nav === 'all'"
        >
          <el-select v-model="user_type" placeholder="请选择身份类型">
            <el-option
              :key="item.id"
              :label="item.name"
              :value="item.id"
              v-for="item in userTypeOptions"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="签到状态："
          prop="sign_status"
          v-show="
            filterForm.nav === 'cycuser' ||
            (filterForm.nav === 'non_cycuser' && filterForm.tab === 'checked')
          "
        >
          <el-select v-model="sign_status" placeholder="请选择签到状态">
            <el-option
              :key="item.id"
              :label="item.name"
              :value="item.id"
              v-for="item in signStatusOptions"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="数据类型：" prop="sign_type">
          <el-select v-model="sign_type" placeholder="请选择数据类型">
            <el-option
              :key="item.id"
              :label="item.name"
              :value="item.id"
              v-for="item in jionTypeOptions"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态：" prop="order_status">
          <el-select v-model="order_status" placeholder="请选择订单状态">
            <el-option
              :key="item.id"
              :label="item.name"
              :value="item.id"
              v-for="item in orderStatusOptions"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker
          :end-time.sync="form.join_end_time"
          :start-time.sync="form.join_start_time"
          end-prop="join_end_time"
          label="报名时间："
          start-prop="join_start_time"
        />
      </div>
    </el-form>
    <template #footer="{ labelWidth }">
      <template v-if="progress > 0 && !quit">
        <p
          :style="{ marginLeft: labelWidth }"
          style="margin-top: 20px; font-size: 14px; color: #3576ff"
        >
          {{ progress > 0 ? `正在导出附件，进度 ${progress} %` : '' }}
          <el-button @click="quitExporting" type="text" v-if="progress < 100">取消</el-button>
        </p>
      </template>
    </template>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import {
  documentMemberList,
  exportActivityFormFiles,
} from '../../api/activity-list/list'
import { getTemporaryFileUrl } from '@/modules/common/api/media.js'
import { positionLevel } from '../../api/sign-up-list/list'
import { saveAs } from 'file-saver'
import {
  runPromiseByQueue,
  sliceArrFn,
  timestamp,
} from '../../../../base/utils/tool'
import { exportFilesTool } from '../../../common/utils/exportFiles'

export default {
  model: {
    prop: 'filterForm',
    event: 'updateFilter',
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.getPositionLevel()
  },
  data() {
    return {
      rules: {},
      // 联级选择配置
      props: {
        value: 'id',
        label: 'name',
        expandTrigger: 'hover',
        children: 'position',
      },
      parentPositionList: [], // 组织职务选项数据
      signStatusOptions: [
        { name: '未签到', id: 0 },
        { name: '已签到', id: 1 },
      ],
      orderStatusOptions: [
        { name: '已支付', id: 0 },
        { name: '已退款', id: 3 },
        { name: '退款中', id: 4 },
      ],
      userTypeOptions: [
        { name: '会员', id: 0 },
        { name: '非会员', id: 1 },
      ],
      jionTypeOptions: [
        { name: '客户端报名', id: 0 },
        { name: '后台报名', id: 1 },
      ],
      // 筛选表单名称配置
      userNameLabel: {
        cycuser: '会员名称',
        non_cycuser: '用户名称',
        all: '姓名',
      },

      progress: '',
      quit: false,
    }
  },
  methods: {
    quitExporting() {
      this.quit = true
      if (this._ctx) this._ctx.stop()
    },
    // 获取架构职务
    getPositionLevel() {
      positionLevel({ is_list_tree: 0, is_show_all: 1, data_auth_filter: 0 })
        .then((res) => {
          this.parentPositionList = res.data
        })
        .catch((err) => {
          console.log(err, 'error')
        })
    },
    //重置表单
    resetForm() {
      this.$refs['logFilterForm'].resetFields()
      this.uploadFilter(this.form)
    },
    // 导出列表
    onExport() {
      const postData = { ...this.form, export_ids: this.ids }
      return documentMemberList(postData).catch((err) => {})
    },
    async onExportFiles() {
      this.quit = false
      this.progress = ''
      const postData = { ...this.form, export_ids: this.ids }
      const { data } = await exportActivityFormFiles(postData).catch(() => ({
        data: null,
      }))
      if (!data) return // 接口报错
      const { list } = data
      if (!list.length) {
        this.$message.info('没有需要导出的附件')
        return
      }

      const exportTool = new exportFilesTool({
        onProgress: (percent) => {
          this.progress = percent
        }
      })
      this._ctx = exportTool
      await exportTool.start(list)

      console.log('全部结束')
      this.progress = '' // 进度日志消失
    },
    // async exportPersonally(user_file_list = [], name = '') {
    //   const exportTool = new exportFilesTool({
    //     zipFileName: name,
    //     onProgress: (percent) => {
    //       let count = this.count + 1
    //       this.progress = percent * (count / this._sum)
    //       console.log(percent * (count / this._sum), 'progress')
    //     }
    //   })
    //   this._ctx = exportTool
    //   return await exportTool.start(user_file_list)
    // },
    //筛选
    ok() {
      this.uploadFilter(this.form)
    },
  },
  computed: {
    form: {
      get() {
        return this.filterForm
      },
      set(val) {
        this.$emit('updateFilter', val)
      },
    },
    sign_type: {
      get() {
        return this.form.sign_type == -1 ? '' : this.form.sign_type
      },
      set(val) {
        this.form.sign_type = val
      },
    },
    order_status: {
      get() {
        return this.form.order_status == -1 ? '' : this.form.order_status
      },
      set(val) {
        this.form.order_status = val
      },
    },
    user_type: {
      get() {
        return this.form.user_type == -1 ? '' : this.form.user_type
      },
      set(val) {
        this.form.user_type = val
      },
    },
    sign_status: {
      get() {
        return this.form.sign_status == -1 ? '' : this.form.sign_status
      },
      set(val) {
        this.form.sign_status = val
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
}
</script>
