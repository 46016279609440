<!--
 * @Author: dongjia
 * @Date: 2022-02-12 18:15:52
 * @LastEditTime: 2022-02-12 18:44:41
 * @LastEditors: aleaner
 * @Description: 报名名单查看弹窗
 * @FilePath: \activity\components\ActivityList\JoinMemberDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="查看" :visible.sync="openDialog" width="500px" :show-close="true" class="dialog-vertical">
    <div class="dialog-content" v-loading="loading">
      <template v-if="detail">
        <div class="form-item" v-for="(item,index) in form_configs" :key="index">
          <p class="form-label">{{item.label}}：</p>
          <p class="form-content">{{detail[item.key]}}</p>
        </div>
      </template>
    </div>
    <span slot="footer">
      <el-button @click="openDialog = false" size="small">取消</el-button>
      <el-button type="primary" size="small" @click="openDialog = false">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { joinDetail } from "../../api/sign-up-list/list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String],
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      detail: null,
      form_configs: [
        { key: "nickname", label: "姓名" },
        { key: "phone", label: "手机号码" },
        { key: "user_type_text", label: "身份类型" },
        { key: "sign_type_text", label: "数据类型" },
        { key: "join_time", label: "报名时间" },
      ],
    };
  },
  methods: {
    getJoinDetail() {
      this.loading = true;
      joinDetail({ id: this.id })
        .then((res) => {
          const { data } = res;
          this.detail = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.getJoinDetail();
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-content {
  .form-item + .form-item {
    margin-top: 24px;
  }
  .form-item {
    display: flex;
    align-items: center;
    .form-label {
      width: 70px;
      text-align: right;
    }
  }
}
</style>
