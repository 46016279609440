import api from "@/base/utils/request";
import fetchFile from "@/base/utils/download";

// 获取活动列表
export const ActivityList = data => {
  return api({
    url: "/admin/cyc_activity/activity/index",
    method: "post",
    data
  });
};

// 获取所属组织列表
export const ParentList = data => {
  return api({
    url: "/admin/cyc/brand/parents",
    method: "post",
    data
  });
};

// 更新推荐状态
export const updateRecommend = data => {
  return api({
    url: "/admin/cyc_activity/activity/updateRecommend",
    method: "post",
    data
  });
};

// 更新排序
export const updateSort = data => {
  return api({
    url: '/admin/cyc_activity/activity/updateSort',
    method: 'post',
    data
  });
};

// 更新显示
export const updateShow = data => {
  return api({
    url: "/admin/cyc_activity/activity/updateShow",
    method: "post",
    data
  });
};


// 取消活动
export const CancelActivity = data => {
  return api({
    url: "/admin/cyc_activity/activity/updateCancel",
    method: "post",
    data
  });
};

// 删除移入回收站
export const softDelete = data => {
  return api({
    url: "/admin/cyc_activity/activity/softDelete",
    method: "post",
    data
  });
};

// 回收站永久删除
export const Delete = data => {
  return api({
    url: "/admin/cyc_activity/activity/del",
    method: "post",
    data
  });
};

// 回收站还原数据
export const putBack = data => {
  return api({
    url: "/admin/cyc_activity/activity/putBack",
    method: "post",
    data
  });
};

// 报名名单导出
export const documentMemberList = data => {
  return fetchFile({
    url: "/admin/cyc_activity/ActivityUser/export",
    method: "post",
    data,
    download: true
  });
};

export const exportActivityFormFiles = data => {
  return api({
    url: '/admin/cyc_activity/ActivityUser/exportFiles',
    method: 'post',
    data,
  })
}

// 活动列表导出
export const documentActivityList = data => {
  return fetchFile({
    url: "/admin/cyc_activity/activity/export",
    method: "post",
    data,
    download: true
  });
};
//活动分类下拉框
export const getCategory = data => {
  return api({
    url: "/admin/cyc_activity/activity_category/all",
    method: "post",
    data
  });
};

/**
 * 将这些活动转移到其他分类，原来的分类下就不再有这些活动
 * @param data
 */
export const moveMany = (data) => {
  return api({url:'/admin/cyc_activity/activity/moveCategories', method: 'post', data})
}

//发布
export const publish = data => {
  return api({
    url: "/admin/cyc_activity/activity/publish",
    method: "post",
    data
  });
};

//撤销发布
export const withdraw = data => {
  return api({
    url: "/admin/cyc_activity/activity/withdraw",
    method: "post",
    data
  });
};
//复制
export const copyActivityInfo = data => {
  return api({
    url: "/admin/cyc_activity/activity/copyActivityInfo",
    method: "post",
    data
  });
};
