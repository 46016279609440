<!--
 * @Author: dongjia
 * @Date: 2022-02-12 15:30:32
 * @LastEditTime: 2022-02-12 17:39:12
 * @LastEditors: aleaner
 * @Description: 非会员选择弹窗
 * @FilePath: \activity\components\ActivityList\JoinNotMemberSelector.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    :title="handleSaaSVersionKeyword('选择非会员')"
    :visible.sync="openDialog"
    width="60%"
    :show-close="false"
    class="dialog-vertical"
    @close="cancel"
  >
    <div class="head-filter">
      <el-input v-model="filterForm.phone" placeholder="请输入手机号码" @change="handleFilter">
        <i class="el-icon-search el-input__icon" slot="suffix"></i>
      </el-input>
      <el-input v-model="filterForm.nickname" placeholder="请输入用户名称" @change="handleFilter">
        <i class="el-icon-search el-input__icon" slot="suffix"></i>
      </el-input>
    </div>
    <div class="member-list" style="margin-top: 35px">
      <el-table
        :data="memberList"
        style="width: 100%"
        :height="250"
        ref="multipleTable"
        :highlight-current-row="!selectType"
        @row-click="handleCurrentChange"
        @selection-change="handleSelectionChange"
        v-loading="loading"
        v-loadmore="LoadMore"
      >
        <!-- 多选 -->
        <el-table-column
          ref="selection"
          fixed
          type="selection"
          width="70"
          :selectable="selectable"
          v-if="selectType"
          :key="Math.random()"
        ></el-table-column>
        <!-- 单选 -->
        <el-table-column fixed width="70" v-if="!selectType" :key="Math.random()">
          <template slot-scope="scope">
            <el-radio
              :label="scope.row"
              v-model="currentRow"
              :disabled="selectedMembers.findIndex((item) => item.user_id === scope.row.user_id) !== -1"
            >
              {{ "&nbsp" }}</el-radio
            >
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column :prop="th.prop" :label="th.label" :width="th.width || ''" :key="index"> </el-table-column>
        </template>
      </el-table>
    </div>
    <template #footer>
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit" :loading="saveLoading">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addJoinUser, nonMemberList } from "../../api/sign-up-list/list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    // 已选择会员数据
    Members: {
      type: Array,
      default: () => [],
    },
    // 0:单选/1:多选模式
    selectType: {
      type: Number,
      default: 1,
    },
    update: {
      type: Function,
    },
    // 当前选中索引
    currentIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false, //加载
      saveLoading: false,
      thead: [
        { label: "用户名称", prop: "nickname" },
        { label: "手机号码", prop: "phone" },
      ],
      memberList: [],
      //筛选对象
      filterForm: {
        nickname: "",
        phone: "",
        page_size: 10, //每页多少条数据
        page: 1,
      },
      getable: true, //是否可以请求列表
      // 联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
      currentRow: "", // 单选时选中的数据项
      selectArr: [], //勾选的数组id
    };
  },
  methods: {
    // 获取会员列表
    getNonMemberList(pageData) {
      if (this.getable) {
        this.loading = true;
        nonMemberList(pageData)
          .then((res) => {
            const { data } = res;
            if (pageData.page === 1) {
              this.memberList = data.data;
            } else {
              this.memberList = [...this.memberList, ...data.data];
            }
            if (data.data.length < this.filterForm.page_size) {
              this.getable = false;
            }
            this.filterForm.page++;
            this.loading = false;
          })
          .catch((err) => {});
      }
    },
    // 判断是否可选
    selectable(row, index) {
      if (this.selectedMembers.findIndex((item) => item.user_id === row.user_id) !== -1) {
        return false;
      } else {
        return true;
      }
    },
    // 筛选操作
    handleFilter(e, key) {
      const fliterData = { ...this.filterForm };
      fliterData.page = key ? fliterData.page : 1;
      this.getable = true;
      this.getNonMemberList(fliterData);
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        selection.forEach((el) => {
          this.selectArr.push(el.user_id);
        });
      }
    },
    // 点击数据项操作
    handleCurrentChange(val) {
      if (this.selectedMembers.findIndex((item) => item.user_id === val.user_id) !== -1) {
        return;
      } else {
        this.memberList.forEach((element) => {
          if (val.user_id === element.user_id) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(element);
            });
          }
        });
      }
    },
    //触底刷新
    LoadMore() {
      if (this.getable) {
        this.handleFilter(this.filterForm, "Load");
      }
    },
    // 确定
    submit() {
      if (!this.selectArr.length) {
        this.$message.info("请选择数据项");
      } else {
        this.saveLoading = true;
        const data = {
          user_id: this.selectArr,
          id: this.$route.params.id,
          is_non_cycuser: 1,
        };
        addJoinUser(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.$refs.multipleTable.clearSelection();
            this.selectArr = [];
            this.update();
            this.saveLoading = false;
            this.openDialog = false;
          })
          .catch((err) => {
            this.saveLoading = false;
          });
      }
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
    },
  },
  created() {
    const data = { ...this.filterForm };
    this.getNonMemberList(data);
  },
  watch: {
    memberList: {
      deep: true,
      handler(val) {
        this.memberList.forEach((el) => {
          if (this.selectArr.findIndex((elem) => elem === el.user_id) !== -1 && this.$refs.multipleTable) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(el, true);
            });
          }
        });
      },
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    selectedMembers: {
      get() {
        return this.Members;
      },
      set(val) {
        // this.$emit("changeSelect", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.head-filter {
  width: 50%;
  display: flex;
  position: absolute;
  top: 40px;
  right: 40px;

  .el-select,
  .el-cascader,
  .el-input {
    flex: 1;
    margin-right: 10px;
  }
}

.member-list {
  ::v-deep.el-table__row {
    cursor: pointer;
  }
}
</style>
