<template>
  <el-upload
    class="upload-file-turn-text"
    ref="upload"
    action="#"
    :limit="limit"
    :http-request="customUpload"
    accept=".xlsx, .xls"
    :file-list="fileList"
    :show-file-list="false"
    :disabled="loading"
    :on-exceed="handleExceed"
  >
    <template v-if="typeof buttonText === 'string'">
      <el-button
        :size="buttonSize"
        type="primary"
        style="min-width: 100px"
        :loading="loading"
        :icon="buttonIcon"
      >
        {{ loading ? (progress ? `${progress}%` : '导入中') : buttonText }}
      </el-button>
    </template>
    <template v-else>
      <!--      <el-button-group>-->
      <!--        <el-button :size="buttonSize" type="primary" :loading="loading">-->
      <!--          <i v-if="buttonIcon && !loading" :class="buttonIcon"></i>-->
      <!--          {{loading?'导入中':buttonText[0]}}-->
      <!--        </el-button>-->
      <!--        <el-button :size="buttonSize" type="primary" :loading="loading">-->
      <!--          <i v-if="buttonIcon && !loading" :class="buttonIcon"></i>-->
      <!--          {{loading?'导入中':buttonText[1]}}-->
      <!--        </el-button>-->
      <!--      </el-button-group>-->
    </template>
    <template #tip>
      <div v-if="tip" class="el-upload__tip">
        <i v-if="tipIcon" :class="tipIcon"></i>
        {{ tip }}
      </div>
    </template>
  </el-upload>
</template>
<script>
import { placeImport } from '../../../api/sign-up-list/list'

import {
  readXlsxFileData,
  runPromiseByQueue,
  sliceArrFn,
} from '@/base/utils/tool'

export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Function,
    },
    //按钮文字
    buttonText: {
      type: [String, Array],
      default: '点击上传',
    },
    //按钮大小
    buttonSize: {
      type: String,
      default: 'small',
    },
    //按钮图标
    buttonIcon: {
      type: String,
      default: 'el-icon-upload2',
    },
    //提示文字
    tip: String,
    //提示文字图标
    tipIcon: String,
    onCustomUpload: Function,
  },
  watch: {
    open(val) {
      if (val) {
        this.$refs.upload.clearFiles()
        this.loading = false
        this.progress = 0
      }
    },
  },
  data() {
    return {
      limit: 1, //限制文件数量
      fileList: [], //上传的文件数组，展示用
      loading: false, //转换中
      progress: 0,
    }
  },
  methods: {
    //文件限制超出
    handleExceed(file, fileList) {
      this.$message.error('最多可上传一个文件')
    },
    openFullScreen() {
      this.loadingObj = this.$loading({
        lock: true,
        text: '正在批量更新……，进度 0 %',
      })
    },
    // 自定义上传
    customUpload(uploadObj) {
      this.loading = true

      if (this.fileList.length > 0) {
        this.fileList = []
      }

      readXlsxFileData(
        uploadObj.file,
        (data, titles, list) => {
          this.$refs.upload.clearFiles()
          const size = 100
          const sliceArr = sliceArrFn(list, size)
          const errMsgObjArr = []
          let count = 0
          runPromiseByQueue(
            sliceArr.map((s, sI) => {
              return () =>
                new Promise((resolve) => {
                  let handler
                  if (this.onCustomUpload !== undefined) {
                    handler = this.onCustomUpload
                  } else {
                    handler = placeImport
                  }
                  console.log('上传中')
                  handler(uploadObj.file, {
                    activity_id: this.$route.params.id,
                  })
                    .then((res) => {
                      console.log(res, 'resres')
                      count++
                      if (count === sliceArr.length) {
                        this.progress = 100
                      } else {
                        this.progress = Math.floor(
                          (count * 100) / sliceArr.length
                        )
                      }
                      resolve(res)
                    })
                    .catch((err) => {
                      console.log(err)
                      errMsgObjArr.push({
                        msg: err.msg,
                        status: 0,
                      })
                      resolve()
                    })
                })
            })
          )
            .then((res) => {
              console.log(errMsgObjArr, res, 'errMsgObjArr')
              if (errMsgObjArr.filter((e) => e.status === 0).length) {
                console.log(errMsgObjArr)
                this.$msgbox
                  .confirm(
                    errMsgObjArr
                      .filter((e) => e.status === 0)
                      .map((e) => e.msg)
                      .join('；<br/>'),
                    '导入失败',
                    {
                      type: 'error',
                      showCancelButton: false,
                      dangerouslyUseHTMLString: true,
                    }
                  )
                  .then(() => {})
                  .catch(() => {})
              } else {
                // 判断成功还是失败
                if (res.data.status) {
                  this.$message.success(res.data.msg)
                } else {
                  this.$message.error(res.data.msg)
                }
              }

              this.loading = false
              this.update()
            })
            .catch(() => {})
        },
        () => {
          this.$refs.upload.clearFiles()
          this.loading = false
          this.progress = 0
        }
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-file-turn-text {
  ::v-deep .el-list-enter-active,
  ::v-deep .el-list-leave-active {
    transition: none;
  }

  ::v-deep .el-list-enter,
  ::v-deep .el-list-leave-active {
    opacity: 0;
  }
  .el-upload__tip {
    line-height: 1;
  }
  ::v-deep.el-upload-list__item {
    width: 202px;
  }
}

.el-upload-list {
  display: none;
}
ul {
  display: none;
}

/*.el-button-group {*/
/*  .el-button {*/
/*    margin-left: 0*/
/*  }*/
/*}*/
</style>
