<template>
  <div>
    <el-dialog
      title="定向邀请"
      :visible.sync="openDialog"
      width="700px"
      class="dialog-vertical"
      @close="cancel('editForm')"
      @open="openDialogFn"
    >
      <el-button v-if="isCycOrganization" type="primary" @click="AddUser"> 添加会员 </el-button>
      <el-button type="primary" @click="AddTourists"> 添加游客 </el-button>
      <el-button type="primary" @click="exportList"> 导出列表 </el-button>

      <el-table
        style="margin-top: 10px"
        :data="tbody"
        :height="350"
        ref="multipleTable"
        v-loading="loading"
        v-loadmore="LoadMore"
        class="thead-light"
      >
        <el-table-column type="index" label="序号"> </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
          >
            <template slot-scope="scope">
              <!-- 会内职务 -->
              <template v-if="th.prop == 'title_name'">
                <span v-if="scope.row.type">--</span>
                <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
              </template>
              <!-- 社会职务 -->
              <template v-else-if="th.prop == 'social_position'">
                <span v-if="!scope.row.type">--</span>
                <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
              </template>
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
        <el-table-column label="操作" min-width="100" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="showInvitePost(scope.row)"
              >邀请海报</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 邀请游客 -->
    <AddInviteUserDialog v-model="AddTouristsDialog" :update="refreshList" />
    <!-- 邀请会员 -->
    <InviteMemberSelector
      :update="refreshList"
      :selectType="0"
      v-model="AddUserDialog"
      :Members="tbody"
    />
    <!-- 邀请海报弹窗 -->
    <InvitePostDialog
      :showCode="InvitePost"
      @closeCode="closeInvitePostDialog"
      :id="currentId"
    >
    </InvitePostDialog>
  </div>
</template>

<script>
import { inviteUserList, exportInviteUser } from "../../api/sign-up-list/list";
import debounce from "lodash/debounce";
import AddInviteUserDialog from "./AddInviteUserDialog";
import InviteMemberSelector from "./InviteMemberSelector";
import InvitePostDialog from "./InvitePostDialog";

export default {
  components: {
    AddInviteUserDialog,
    InviteMemberSelector,
    InvitePostDialog,
  },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      saveLoading: false,
      loading: false,
      tbody: [],
      filterForm: {
        activity_id: this.$route.params.id || this.$route.query.id,
        page: 1,
        page_size: 15,
      },
      thead: [
        { label: "用户名称", prop: "user_name" },
        { label: "会内职务", prop: "title_name" },
        { label: "社会职务", prop: "social_position" },
        { label: "用户类型", prop: "type_text" },
        { label: "报名状态", prop: "sign_up_status" },
      ],
      InvitePost: false,
      AddUserDialog: false,
      AddTouristsDialog: false,
      currentId: "",
    };
  },
  methods: {
    // 导出邀请列表
    exportList() {
      exportInviteUser({ activity_id: this.$route.params.id || this.$route.query.id, export_ids: [] });
    },
    // 取消
    cancel(formName) {
      this.tbody = [];
      this.filterForm = {
        activity_id: this.$route.params.id || this.$route.query.id,
        page: 1,
        page_size: 15,
      };
      this.openDialog = false;
    },
    getInviteList(type) {
      this.loading = true;
      inviteUserList(this.filterForm)
        .then((res) => {
          this.loading = false;
          if (type === "refresh") {
            this.tbody = res.data.data;
          } else {
            this.tbody = this.tbody.concat(res.data.data);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    refreshList() {
      this.filterForm.page = 1;
      this.getInviteList("refresh");
    },
    // 打开弹窗
    openDialogFn() {
      this.getInviteList();
    },
    //触底刷新
    LoadMore: debounce(function () {
      if (this.tbody.length >= 15 * this.filterForm.page) {
        this.filterForm.page++;
        this.getInviteList();
      }
    }, 200),
    showInvitePost(row) {
      this.currentId = row.id;
      this.InvitePost = true;
    },
    // 添加会员
    AddUser() {
      this.AddUserDialog = true;
    },
    // 添加游客
    AddTourists() {
      this.AddTouristsDialog = true;
    },
    closeInvitePostDialog() {
      this.InvitePost = false;
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    isCycOrganization() {
      return this.$store.getters.tenant.features.includes('cyc_organization')
    },
  },
};
</script>

<style langt="scss" scoped>
.bottom-btn {
  width: 100%;
  margin-top: 8px;
  border: 1px dashed rgba(217, 217, 217, 1);
  font-size: 16px;
  padding: 8px 0;
}

.bottom-btn:hover {
  border: 1px dashed #3576ff;
}
</style>
