<template>
  <el-dialog
    title="批量导入报名名单"
    :visible.sync="openDialog"
    width="600px"
    class="dialog-vertical"
  >
    <div class="dialog-content">
      <div class="tips">
        提示：
        仅支持无需支付报名费用的用户，如需支付报名费用，请单独添加报名信息
      </div>
      <div class="content-title">请按以下步骤操作：</div>
      <div class="content-step">
        <div>步骤1：下载《批量导入报名名单模版》，按模板中的说明进行填写。</div>
        <el-button
          class="btn"
          type="text"
          style="margin-left: 10px"
          @click="downloadExcel"
          >下载模板</el-button
        >
      </div>
      <div class="content-step">步骤2：上传文件后系统会自动进行批量导入。</div>
    </div>
    <div slot="footer">
      <div class="footer-btn">
        <el-button size="small" @click="openDialog = false">关闭</el-button>

        <UploadFileButton
          :open="openDialog"
          :update="update"
          button-text="上传文件"
        />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import UploadFileButton from "./UploadFileButton.vue";
import { signImportXlsx } from "../../../api/sign-up-list/list";

export default {
  components: { UploadFileButton },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      detailForm: null,
      downloadUrl:
        window.serverConfig.VUE_APP_ADMINURL +
        "/cyc/files/%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E4%BC%9A%E5%91%98%E6%A8%A1%E6%9D%BF.xlsx",
    };
  },
  methods: {
    // 下载导入会员文件模板
    downloadExcel() {
      signImportXlsx({ activity_id: this.$route.params.id || this.$route.query.id });
    },
    // 导入完成操作
    update() {
      this.openDialog = false;
      this.updateList();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  font-size: 12px;
  color: #b3b3b3;
  margin-bottom: 10px;
}
.content-title {
  line-height: 1;
  color: #3a3a3a;
}
.content-step {
  line-height: 1;
  color: #3a3a3a;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btn {
    padding: 0;
    margin-right: 10px;
  }
}
.footer-btn {
  display: flex;
  float: right;
  padding-bottom: 20px;
  .el-button {
    margin-right: 20px;
  }
}
</style>
