<template>
  <el-dialog
    @open="dialogOpen"
    title="临时报名"
    :visible="showCode"
    width="504px"
    class="dialog-vertical"
    @close="cancel"
  >
    <el-form
      v-loading="loading"
      :model="codeForm"
      size="medium"
      @submit.native.prevent
    >
      <el-form-item>
        <div class="code">
          <div class="content-box">
            <div v-if="isWxApplet" class="item-box">
              <div class="code-box" style="border-radius: 50%">
                <el-image fit="cover" :src="codeForm.mp"></el-image>
              </div>
              <el-button type="text">
                <a
                        style="text-decoration: none"
                        :href="codeForm.mp"
                        download="临时报名小程序码"
                >
                  下载小程序码
                </a>
              </el-button>
            </div>
            <div class="item-box">
              <div class="code-box">
                <el-image fit="cover" :src="codeForm.h5"></el-image>
              </div>
              <el-button type="text">
                <a
                        style="text-decoration: none"
                        :href="codeForm.h5"
                        download="临时报名二维码"
                >
                  下载 H5 微网站二维码
                </a>
              </el-button>
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { temporarySign } from "../../api/sign-up-list/list";
import { copyText } from "@/base/utils/tool";
export default {
  name: "TemporaryDialog",
  props: ["showCode", "activity_id"],
  data() {
    return {
      codeForm: {
        code: "",
        link: "",
        mp: '',
        h5: ''
      },
      loading: false,
    };
  },
  computed: {
    isWxApplet() {
      return this.$store.getters.tenant.features.includes('wx_applet')
    }
  },
  created() {},
  methods: {
    dialogOpen() {
      this.loading = true;

      // 临时报名二维码
      temporarySign({ activity_id: this.activity_id })
        .then((res) => {
          this.codeForm = Object.assign(this.codeForm, res.data);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    cancel() {
      this.$emit("closeCode");
    },
    // 复制
    copy(url) {
      copyText(url).then((valid) => {
        this.$message.success("复制成功");
      });
    },
  },
};
</script>

<style scoped lang="scss">
.code-box {
  width: 320px;
  height: 217px;
  display: flex;
  align-content: center;
  justify-content: center;
}
a:link {
  color: #3576ff;
}
a:hover {
  color: #5d91ff;
}

.code-box {
  width: 210px;
  height: 210px;
  margin: 10px 0;
  display: flex;
  align-content: center;
  justify-content: center;
}
a:link {
  color: #3576ff;
}
a:hover {
  color: #5d91ff;
}


.code {
  .content-box {
    background-color: #f2f2f2;
    padding: 16px;
    display: flex;
    justify-content: space-around;
    .item-box {
      width: 180px;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .code-box {
        width: 180px;
        height: 180px;
        margin: 10px 0;

        background-color: #fff;

        overflow: hidden;
      }
    }

  }
}
</style>
