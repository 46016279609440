<template>
  <div class="content-box">
    <el-dialog
      title="退还费用"
      :visible="is_show"
      width="500px"
      class="dialog-vertical"
      @close="close()"
      @open="openDialog"
    >
      <el-form
        size="medium"
        class="medium-form"
        :model="form"
        :rules="rules"
        label-width="65px"
        ref="form"
        @submit.native.prevent="() => {}"
      >
        <el-form-item
          prop="content"
          style="margin-bottom: 0"
          label="费用选择："
        >
          <el-radio-group v-model="form.refoundType">
            <el-radio :label="0">全部退还</el-radio>
            <el-radio :label="1">仅退还额外费用</el-radio>
            <!-- <el-radio :label="2">仅退还报名费用</el-radio> -->
          </el-radio-group>
          <div class="tips">
            <div v-if="form.refoundType === 0">
              <template v-if="this.$props.ids.length">
                全部退还：退还报名费+额外费用。
              </template>
              <template v-else>
                全部退还：退还报名费+额外费用。共退还：报名费
                {{ this.$props.currentRow.pay_money }}、额外费用{{
                  this.$props.currentRow.extra_pay_text
                }}
              </template>
            </div>
            <div v-if="form.refoundType === 1">
              <template v-if="this.$props.ids.length">
                仅退还额外费用
              </template>
              <template v-else>
                仅退还额外费用： 共退还：额外费用{{
                  this.$props.currentRow.extra_pay_text
                }}
              </template>
            </div>
            <div v-if="form.refoundType === 2">
              <template v-if="this.$props.ids.length">
                仅退还报名费用
              </template>
              <template v-else>
                仅退还报名费用： 共退还：报名费
                {{ this.$props.currentRow.pay_money }}
              </template>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="close()" size="small">取消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          size="small"
          @click="submit()"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { refundPart, refundAll } from "../../../api/sign-up-list/list";
export default {
  name: "EntranceDialog",
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    // 弹窗配置
    open: {
      type: Boolean,
      detault: false,
    },
    ids: {
      type: Array,
    },
    currentRow: {
      type: Object,
    },
  },
  components: {},
  created() {},
  computed: {
    // 显示框
    is_show: {
      get() {
        return this.$props.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
  },
  watch: {},

  data() {
    return {
      form: {
        refoundType: 0,
      },
      rules: {},
      loading: false,
    };
  },
  methods: {
    close() {
      this.is_show = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let id = this.$props.ids.length
            ? this.$props.ids
            : this.$props.currentRow.id;
          this.loading = true;
          if (this.form.refoundType === 1) {
            // 退额外费用
            refundPart({ id }).then((res) => {
              this.loading = false;
              this.$message.success('退还成功！')

              this.close();
              this.$emit('updateList')
            });
          } else if (this.form.refoundType === 2){
            // 退报名费用
      
            // refundPart({ id }).then((res) => {
            //   this.loading = false;
            //   this.$message.success('退还成功！')

            //   this.close();
            //   this.$emit('updateList')
            // });
          }
          else {
            // 退全部
            refundAll({ id }).then((res) => {
              this.loading = false;
              this.$message.success('退还成功！')
              this.$emit('updateList')

              this.close();
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });

    },
    openDialog() {
      console.log(this.$props.currentRow);
    },
  },
};
</script>
<style lang="scss" scoped>
.tips {
  font-size: 14px;
  color: #b3b3b3;
  line-height: 20px;
}
</style>
