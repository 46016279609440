<!--
 * @Author: mulingyuer
 * @Date: 2021-07-12 14:23:17
 * @LastEditTime: 2021-08-04 14:39:25
 * @LastEditors: mulingyuer
 * @Description: 更多按钮
 * @FilePath: \super-admin-vue\src\base\components\Table\MoreBtn.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dropdown :trigger="trigger" :size="size" :placement="placement" class="more-btn" @command="onCommand">
    <slot name="more">
      <el-button size="small" type="text">更多</el-button>
    </slot>
    <el-dropdown-menu slot="dropdown">
      <slot>
        <el-dropdown-item>暂无操作</el-dropdown-item>
      </slot>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  props: {
    //位置
    placement: {
      type: String,
      default: "bottom",
    },
    // 大小
    size: {
      type: String,
      default: "medium",
    },
    trigger: {
      type: String,
      default: "click",
    }
  },
  methods: {
    onCommand(name) {
      this.$emit("command", name); //传递出去
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.el-dropdown-menu__item {
  white-space: nowrap;
}
</style>
