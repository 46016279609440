<template>
  <div>
    <el-form
      size="medium"
      class="medium-form"
      :model="form"
      :rules="rules"
      label-width="120px"
      ref="form"
      @submit.native.prevent="() => {}"
    >
      <el-form-item
        v-for="(item, i) in formArray"
        :key="item.name"
        :label="`${item.name}： `"
        :prop="item.name"
      >
        <template v-if="item.type === 'select'">
          <el-radio-group v-model="form[item.name]">
            <el-radio v-for="(option, i2) in item.options" :key="option.text" :label="i2">{{
              option.text
            }}
<!--            <template v-if="option.pay_amount">-->
<!--              (需支付{{option.pay_type ? '' : '￥'}}{{option.pay_amount}}{{option.pay_type ? '积分' : ''}})-->
<!--            </template>-->
            </el-radio>
          </el-radio-group>
        </template>
        <template v-else-if="item.type === 'input'">
          <el-input :maxlength="item.text_limit" v-model="form[item.name]"></el-input>
        </template>
        <template v-else-if="item.type === 'multi-select'">
          <el-checkbox-group v-model="form[item.name]">
            <el-checkbox
              v-for="(option, i2) in item.options"
              :label="`${option.text}`"
              :value="i2"
              :key="option.timestamp"
            >
              {{option.text}}
<!--              <template v-if="option.pay_amount">-->
<!--                (需支付{{option.pay_type ? '' : '￥'}}{{option.pay_amount}}{{option.pay_type ? '积分' : ''}})-->
<!--              </template>-->
            </el-checkbox>
          </el-checkbox-group>
        </template>
      </el-form-item>
    </el-form>
    <!-- {{ form }}
    <button @click="getFormData">123</button> -->
  </div>
</template>

<script>
import { JoinSettings } from "../../../api/activity-list/detail";
export default {
  components: {},
  data() {
    return {
      form: {},
      rules: {},
      formArray: [],
    };
  },
  methods: {
    // 获取自定义表单
    getJoinForm() {
      JoinSettings({ id: this.$route.params.id }).then((res) => {
        console.log(res.data.join_form);
        // 不需要填写信息
        if(res.data.join_form.length === 0){
            this.$emit('hiddenForm')
        }
        this.formArray = res.data.join_form;
        this.formArray.forEach((item) => {
          if (item.required) {
            this.rules[item.name] = [
              { required: true, message: "请填写内容", trigger: "change" },
            ];
          }
          // 多选框，默认是数组
          if (item.type === "multi-select") {
            // this.form[item.name] = [];
            this.$set(this.form, item.name, []);
          }
        });
      });
    },
    // 获取自定义表单实际的数据，只用来给父级调用
    getFormData(cb) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let join_form = [];
          this.formArray.forEach((item) => {
            if (this.form[item.name] !== undefined) {
              if (item.type === "select") {
                join_form.push({
                  type: item.type,
                  name: item.name,
                  options: item.options,
                  required: item.required,
                  text_value: item.options[this.form[item.name]].text,
                });
              } else if (item.type === "input") {
                join_form.push({
                  type: item.type,
                  name: item.name,
                  required: item.required,
                  text_value: this.form[item.name],
                });
              } else if (item.type === "multi-select") {
                // let textArr = [];
                // this.form[item.name].forEach((e) => {
                //   let obj = item.options.filter((filter) => filter.text === e);

                //   textArr.push(obj.text);
                // });
                join_form.push({
                  type: item.type,
                  name: item.name,
                  options: item.options,
                  required: item.required,
                  text_value: this.form[item.name],
                });
              }
            }
          });
          console.log(join_form);
          cb(join_form);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  created() {
    this.getJoinForm();
  },
};
</script>

<style lang="scss" scoped></style>
