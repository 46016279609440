import api from "@/base/utils/request";

// 获取活动详情
export const ActivityDetail = data => {
  return api({
    url: "/admin/cyc_activity/activity/detail",
    method: "post",
    data
  });
};

// 获取所属组织列表
export const ParentList = data => {
  return api({
    url: "/admin/cyc/brand/parents",
    method: "post",
    data,
    notCancel: true
  });
};

//获取一级架构
export const parentOrgList = data => {
  return api({
    url: "/admin/cyc/Organization/joinables",
    method: "post",
    data,
    notCancel: true
  });
};

// 获取架构职务
export const positionLevel = data => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data,
    notCancel: true

  });
};

// 获取小程序直播数据选项列表
export const wxLiveList = data => {
  return api({
    url: "/admin/wx_open/wx_live/allLives",
    method: "post",
    data
  });
};

//保存活动详情
// export const SaveActivity = data => {
//   return api({
//     url: "/admin/cyc_activity/activity/save",
//     method: "post",
//     data
//   });
// };

//活动分类下拉框
export const getCategory = data => {
  return api({
    url: "/admin/cyc_activity/activity_category/all",
    method: "post",
    data
  });
};
// 获取上级组织列表
export const getOrganizationsTree = data => {
  return api({
    url: "/admin/cyc/Organization/OrganizationsTree",
    method: "post",
    data
  });
};
// 获取架构页列表
export const getParentToList = data => {
  return api({
    url: "/admin/cyc/Organization/parentToList",
    method: "post",
    data
  });
};

// 新版详情保存（基本信息）
export const SaveActivity = data => {
  return api({
    url: "/admin/cyc_activity/activity/saveBaseInfo",
    method: "post",
    data
  });
};
//互联协会列表
export const joinGovList = () =>{
  return api({
    url:"/admin/cyc_activity/activity/joinGovList",
    method: "post",
    data:{}
  })
}


// 新版详情保存（报名设置）
export const saveJoinSettings = data => {
  return api({
    url: "/admin/cyc_activity/activity/saveJoinSettings",
    method: "post",
    data
  });
};
// 新版详情保存（其他信息）
export const saveOtherSettings = data => {
  return api({
    url: "/admin/cyc_activity/activity/saveOtherSettings",
    method: "post",
    data
  });
};

// 更新活动显示
export const updateShow = data => {
  return api({
    url: "/admin/cyc_activity/activity/updateShow",
    method: "post",
    data
  });
};

// 获取新版详情（基本信息）
export const getActivity = data => {
  return api({
    url: "/admin/cyc_activity/activity/baseInfo",
    method: "post",
    data,
    selfCancel: false
  });
};
// 获取新版详情（报名详情）
export const JoinSettings= data => {
  return api({
    url: "/admin/cyc_activity/activity/joinSettings",
    method: "post",
    data
  });
};
// 获取新版详情（其他设置）
export const OtherSettings= data => {
  return api({
    url: "/admin/cyc_activity/activity/otherSettings",
    method: "post",
    data
  });
};
// 获取新版详情（完成）
export const FinishedInfo = data => {
  return api({
    url: "/admin/cyc_activity/activity/finishedInfo",
    method: "post",
    data
  });
};
// 获取关联的相册详情
export const detailForActivity = data => {
  return api({
    url: "/admin/cyc_activity/photo/detailForActivity",
    method: "post",
    data
  });
};
// 获取弹窗选择数据列表
export const dataList = (data) => {
  return api({
    url: "/admin/index/dialog/index",
    method: 'post',
    data,
    notCancel: true
  })
}
