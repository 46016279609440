<template>
  <el-dialog
    @open="dialogOpen"
    title="邀请海报"
    :visible="showCode"
    width="415px"
    class="dialog-vertical"
    @close="cancel"
    append-to-body
  >
<!--    <img v-if="url" :src="url" />-->
    <div id="invitation-poster" v-loading="!responseData">
      <div class="poster"></div>
      <template v-if="responseData">
        <img :src="responseData.logo" class="logo" />

        <div class="main flex-col">
          <div class="user">
            <span class="nickname" :style="{lineHeight: responseData.nickname.length >= 4 ? 1.5 : ''}">{{responseData.nickname}}</span>
            <span class="title-name">{{responseData.title_name}}</span>
          </div>
          <div class="desc">{{responseData.content}}</div>
          <div class="labels">
            <div class="label-item">
              <img class="icon" style="top: 396px;" :src="require('@/modules/activity/assets/images/invite-poster/name.png')" />
              <span class="label" style="top: 391px;">{{responseData.activity_name}}</span>
            </div>
            <div class="label-item">
              <img class="icon" style="top: 429px;" :src="require('@/modules/activity/assets/images/invite-poster/time.png')" />
              <span class="label" style="top: 424px;">{{responseData.activity_time_text}}</span>
            </div>
            <div class="label-item">
              <img class="icon" style="top: 462px;" :src="require('@/modules/activity/assets/images/invite-poster/address.png')" />
              <span class="label" style="top: 457px;">{{responseData.activity_address}}</span>
            </div>
          </div>
        </div>

<!--        <img class="icon" style="top: 396px;" :src="require('@/modules/activity/assets/images/invite-poster/name.png')" />-->
<!--        <span class="label" style="top: 391px;">{{responseData.activity_name}}</span>-->
<!--        <img class="icon" style="top: 429px;" :src="require('@/modules/activity/assets/images/invite-poster/time.png')" />-->
<!--        <span class="label" style="top: 424px;">{{responseData.activity_time_text}}</span>-->
<!--        <img class="icon" style="top: 462px;" :src="require('@/modules/activity/assets/images/invite-poster/address.png')" />-->
<!--        <span class="label" style="top: 457px;">{{responseData.activity_address}}</span>-->

        <img :src="responseData.mpcode" class="code" />
      </template>
    </div>
<!--    <el-form-->
<!--      v-loading="loading"-->
<!--      :model="codeForm"-->
<!--      label-width="60px"-->
<!--      size="medium"-->
<!--    >-->
<!--      <el-form-item label="二维码">-->
<!--        <div class="code-box">-->
<!--          <el-image fit="contain" :src="codeForm.code"></el-image>-->
<!--        </div>-->
        <el-button v-if="responseData" type="text" @click="generate" :loading="captureLoading">
<!--          <a-->
<!--            style="text-decoration: none"-->
<!--            class="btn-hover"-->
<!--            :href="url"-->
<!--            download-->
<!--            >-->
            下载
<!--          </a>-->
        </el-button>
<!--      </el-form-item>-->
<!--    </el-form>-->
  </el-dialog>
</template>

<script>
import { inviteUserPoster } from "../../api/sign-up-list/list";
import { toPng } from 'html-to-image'
import { copyText } from "@/base/utils/tool";

export default {
  name: "InvitePostDialog",
  props: ["showCode", "id"],
  data() {
    return {
      responseData: null,
      loading: false,
      // url: '',
      captureLoading: false,
    };
  },
  created() {},
  methods: {
    dialogOpen() {
      this.loading = true;
      this.url = ''
      this.responseData = null

      // 临时报名二维码
      inviteUserPoster({ id: this.id })
        .then((res) => {
          this.responseData = res.data
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$emit("closeCode");
    },
    // 复制
    copy(url) {
      copyText(url).then((valid) => {
        this.$message.success("复制成功");
      });
    },
    generate() {
      if (this.url) {
        let fileName = '邀请海报-' + new Date().getTime() + '.png';
        this.downloadURI(this.url, fileName)
        return
      }
      this.captureLoading = true;
      const node = document.getElementById("invitation-poster");
      toPng(node, { cacheBust: true, width: 375, canvasWidth: 375, pixelRatio: 2,  })
      .then((url) => {
        /* 新标签页打开预览，右键保存 */
        // window.open(URL.createObjectURL(url), "_blank");

        let fileName = '邀请海报-' + new Date().getTime() + '.png';
        this.url = url
        setTimeout(() => {
          this.downloadURI(url, fileName)
          this.captureLoading = false;
        }, 100)
      })
      .catch((err) => {
        console.log(err);
        this.captureLoading = false;
      });
    },
    /**
     * @description: 下载文件方法（仅base64）
     * @param {base64} url 下载地址
     * @param {String} fileName 文件名
     * @Date: 2021-08-19 15:39:03
     * @Author: mulingyuer
     */
    downloadURI(url, fileName = 'stage.png') {
      const link = document.createElement("a");
      link.download = fileName;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link.remove();
    }
  },
};
</script>

<style scoped lang="scss">
.code-box {
  width: 320px;
  height: 217px;
  display: flex;
  align-content: center;
  justify-content: center;
}
a:link {
  color: #3576ff;
}
a:hover {
  color: #5d91ff;
}

  #invitation-poster {
    background: linear-gradient(135.2deg, rgba(238,207,179,1) 0%, rgba(213,172,146,1) 100%);
    width: 375px;
    height: 813px;
    /* margin 属性会导致生成的 png 出现偏移空白 */
    /*margin: 0 auto;*/
    position: relative;
    /* 必须是窗口可见范围内，否则会全黑 */

    .poster {
      width: 375px;
      height: 813px;
      background-image: url("~@/modules/activity/assets/images/invite-poster-bg2.png");
      background-size: cover;
    }

    .logo {
      /*width: 98px;*/
      height: 35px;
      width: auto;
      position: absolute;
      left: 24px;
      top: 33px;
    }

    .main.flex-col {
      position: absolute;
      left: 27px;
      top: 209px;
      right: 27px;
    }

    .user {
/*      display: flex;
      align-items: baseline;
      width: calc(100% - 27 * 2px);*/
    }

    .nickname {
      color: #3C404B;
      font-size: 32px;
      font-weight: bolder;
      line-height: 58px;
      margin-right: 12px;
    }

    .title-name {
      color: #3C404B;
      font-size: 18px;
      line-height: 32px;
    }

    .desc {
      color: rgba(33,37,50,1);
      font-size: 16px;
      line-height: 26px;
      /*position: absolute;*/
      /*top: 279px;*/
      /*left: 24px;*/
      /*right: 24px;*/
      @include clamp(3);
      margin-top: 12px;
      font-family: PingFang SC,serif;
    }

    .labels {
      /*position: absolute;*/
      /*top: 391px;*/
      /*left: 24px;*/
      /*right: 24px;*/
      margin-top: 34px;
    }

    .label-item {
      /*position: relative;*/
      display: flex;

      & + .label-item {
        margin-top: 12px;
      }
    }

    .icon {
      width: 12px;
      height: 12px;
      /*position: absolute;*/
      /*left: 24px;*/
      flex-shrink: 0;
      margin-top: 5px;
      margin-right: 8px;
    }

    .label {
      color: rgba(34,17,34,1);
      font-size: 14px;
      line-height: 21px;
      /*left: 47px;*/
      /*right: 24px;*/
      /*position: absolute;*/
    }

    .code {
      width: 88px;
      height: 88px;
      top: 686px;
      left: 143px;
      position: absolute;
      border-radius: 50%;
      background-color: white;
    }
  }

</style>
