<template>
  <div>
    <el-dialog
      title="添加邀请用户"
      :visible.sync="openDialog"
      width="600px"
      class="dialog-vertical"
      @close="cancel('editForm')"
      @open="openDialogFn"
    >
      <el-form
        size="small"
        :model="filterForm"
        label-position="right"
        label-width="100px"
        class="form small-form"
        :rules="rules"
        ref="editForm"
        v-loading="loading"
        @submit.native.prevent
      >
        <el-form-item label="用户类型：">
          <el-radio-group v-model="filterForm.type">
            <!-- <el-radio :label="0">会员</el-radio> -->
            <el-radio :label="1"> 游客</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item
          v-if="filterForm.type == 0"
          prop="use_id"
          label="选择会员："
        >
          <el-button type="primary" @click="openSelect">选择会员</el-button>
        </el-form-item> -->
        <el-form-item
          v-if="filterForm.type == 1"
          prop="name"
          label="用户名称："
        >
          <el-input v-model="filterForm.name"></el-input>
        </el-form-item>
        <el-form-item v-if="filterForm.type == 1" label="社会职务：">
          <el-input v-model="filterForm.social_position"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="openDialog = false">取消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="submit('editForm')"
          :loading="loading"
          >添加</el-button
        >
      </span>
    </el-dialog>
    <!-- <InviteMemberSelector
      v-model="openSelectMember"
      :Members="tbody"
    /> -->
  </div>
</template>

<script>
import {
  inviteUserList,
  addInviteUser,
  inviteUserPoster,
} from "../../api/sign-up-list/list";
import InviteMemberSelector from "./InviteMemberSelector";
export default {
  components: {
    InviteMemberSelector,
  },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      openSelectMember: false,
      loading: false,
      tbody: [],
      filterForm: {
        activity_id: this.$route.params.id || this.$route.query.id,
        type: 1,
        user_id: "",
        name: "",
        social_position: "",
      },

      rules: {
        user_id: [{ required: true, message: "请选择会员", trigger: "change" }],
        name: [
          { required: true, message: "请输入用户名称", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    openSelect() {
      this.openSelectMember = true;
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          addInviteUser(this.filterForm)
            .then((res) => {
              this.$message.success(res.msg);
              this.loading = false;
              this.openDialog = false;
              this.update();
            })
            .catch((err) => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
    },
    openDialogFn() {},
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style langt="scss" scoped></style>
