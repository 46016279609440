<!--
 * @Author: dongjia
 * @Date: 2022-02-12 16:01:07
 * @LastEditTime: 2022-02-12 16:30:40
 * @LastEditors: aleaner
 * @Description: 非会员审核不通过弹窗
 * @FilePath: \activity\components\ActivityList\JoinNotMemberCheck.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="审核不通过" :visible.sync="openDialog" width="500px" :show-close="true" class="dialog-vertical"
    @close="cancel('reasonContent')">
    <el-form size="medium" :model="_data" :rules="rules" ref="reasonContent" label-width="65px" @submit.native.prevent>
      <el-form-item label="原因：" prop="reasonContent" style="margin-bottom: 0">
        <el-input type="textarea" :rows="3" :maxlength="20" show-word-limit v-model="reasonContent"
          placeholder="请输入"></el-input>
        <div class="form-tips"><i class="el-icon-info" />将通过短信、订阅消息、系统消息通知用户（如报名费用涉及金额、积分将会退回）</div>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false" size="small">取消</el-button>
      <el-button :loading="saveLoading" type="primary" size="small" @click="submit('reasonContent')">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { uncheck } from "../../api/sign-up-list/list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    IDs: {
      type: Array,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      saveLoading: false,
      rules: {
        reasonContent: [
          {
            required: true,
            validator: this.reasonContentPass,
            trigger: "blur",
          },
        ],
      },
      reasonContent: "",
    };
  },
  methods: {
    // 验证内容
    reasonContentPass(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入不通过原因"));
      } else {
        callback();
      }
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          const data = {};
          data.reason = this.reasonContent;
          data.ids = this.IDs;
          uncheck(data)
            .then((res) => {
              this.$message.info(res.msg)
              this.updateList();
              this.$refs[formName].resetFields();
              this.openDialog = false;
              this.saveLoading = false
            })
            .catch((err) => {
              console.log(err)
              this.saveLoading = false
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-tips {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 1.5;
  margin-top: 8px;
  span + span {
    margin-left: 4px;
  }
}
</style>
