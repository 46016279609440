<!--
 * @Author: dongjia
 * @Date: 2022-02-12 18:15:52
 * @LastEditTime: 2022-09-26 12:02:14
 * @LastEditors: zhengjiajun
 * @Description: 报名名单查看弹窗
 * @FilePath: \activity\components\ActivityList\JoinMemberDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    title="修改备注"
    :visible.sync="openDialog"
    width="500px"
    :show-close="true"
    class="dialog-vertical"
    @open="openDialogFn"
  >
    <div class="dialog-content" v-loading="loading">
      <el-form label-position="right" label-width="80px" :model="remakeForm" @submit.native.prevent>
        <el-form-item label="备注：">
          <el-input
            show-word-limit
            :maxlength="100"
            clearable
            type="textarea"
            placeholder="请输入备注"
            v-model="remakeForm.remark"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <el-button @click="openDialog = false" size="small">取消</el-button>
      <el-button type="primary" size="small" @click="saveForm">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {updateRemark} from '../../api/sign-up-list/list'
export default {
  name: "remakeDialog",
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String],
    },
    // 当前操作行的备注
    row_remark: {
      type:String
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      detail: null,
      // 修改备注表单
      remakeForm: {
        remark: "",
      },
    };
  },
  methods: {
    // 保存
    saveForm() {
      const postData = {
        remark: this.remakeForm.remark,
        id: this.id
      }
      console.log(postData);
      // 更新备注
      updateRemark(postData).then(res=> {
        this.$message.success(res.msg)
        this.openDialog = false;
        this.updateList()
      })
    },
    // 打开窗口触发
    openDialogFn(){
      this.remakeForm.remark = this.$props.row_remark;
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-textarea__inner {
  height: 150px;
}
</style>
