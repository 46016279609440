<template>
  <div class="m-steps-area" :style="`width: 100%;`">
    <div class="m-steps">
      <template v-for="n in totalSteps" >
        <div
          v-if="stepsLabel[n - 1].show"
          :class="[
            'm-steps-item',
            {
              finished: current > n,
              process: current === n && n !== totalSteps,
              'last-process': current === totalSteps && n === totalSteps,
              'middle-wait': current < n && n !== totalSteps,
              'last-wait': current < n && n === totalSteps,
            },
          ]"
        >
          <!-- <div class="m-info-wrap" @click="onChange(n)"> -->
          <div class="m-info-wrap" >
            <div class="m-steps-icon">
              <span class="u-icon" v-if="current <= n"></span>
            </div>
            <div class="m-steps-content">
              <div class="u-steps-title">{{ stepsLabel[n - 1].name }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "Steps",
  model: {
    prop: "step",
    event: "changeStep",
  },
  props: {
    stepsLabel: {
      // 步骤标题数组
      type: Array,
      default: () => {
        return [];
      },
    },
    stepsDesc: {
      // 步骤描述数组
      type: Array,
      default: () => {
        return [];
      },
    },
    totalSteps: {
      // 步骤总数
      type: Number,
      default: 3,
    },
    // currentStep: {
    //   // 当前选中的步骤
    //   type: Number,
    //   default: 1,
    // },
    totalWidth: {
      // 步骤条总宽度
      type: Number,
      default: 900,
    },
    descMaxWidth: {
      // 描述文本最大宽度
      type: Number,
      default: 140,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      // 若当前选中步骤超过总步骤数，则默认选择步骤1
      //   current: this.currentStep > this.totalSteps ? 1 : this.currentStep,
    };
  },
  computed: {
    current: {
      get() {
        return this.$props.step;
      },
      set(val) {
        this.$emit("changeStep", val);
      },
    },
  },
  methods: {
    onChange(index) {
      // 点击切换选择步骤
      // e.stopPropagation()
      console.log("index:", index);
      if (this.current !== index) {
        this.current = index;
        this.$emit("change", index);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$themeColor: #1890ff;

@mixin u-tail {
  background: #e8e8e8;
  position: absolute;
  top: 7px;
  left: 70%;
  display: block;
  width: 60%;
  height: 2px;
  content: "";
}
.m-steps-area {
  margin: 0px auto;
  .m-steps {
    padding: 30px 0;
    display: flex;
    .m-steps-item {
      display: inline-block;
      //   overflow: hidden;
      font-size: 16px;
      line-height: 32px;
      flex: 1;
      &:not(:last-child) {
        // margin-right: 16px;
        flex: 1; // 弹性盒模型对象的子元素都有相同的长度，且忽略它们内部的内容
      }
      .m-info-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .m-steps-icon {
          display: inline-block;
          //   margin-right: 8px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          text-align: center;
        }
        .m-steps-content {
          display: inline-block;
          vertical-align: top;
          .u-steps-title {
            position: relative;
            display: inline-block;
            // padding-right: 16px;
          }
          .u-steps-description {
            font-size: 14px;
            word-wrap: break-word;
          }
        }
      }
    }

    .finished {
      position: relative;
    //   &:hover {
    //     .m-steps-content {
    //       .u-steps-title {
    //         color: $themeColor;
    //       }
    //       .u-steps-description {
    //         color: $themeColor;
    //       }
    //     }
    //   }
      &:after {
        @include u-tail;
        background: $themeColor;
      }
      .m-steps-icon {
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-color: $themeColor;
        background-color: $themeColor;

        .u-icon {
          //   fill: $themeColor;
          border: 1px solid $themeColor;
          background-color: $themeColor;
          width: 16px;
          height: 16px;
          vertical-align: top;
          //   margin-top: 8px;
        }
      }
      .m-steps-content {
        color: #3576ff;

        .u-steps-title {
          color: #3576ff;
        }
        .u-steps-description {
          color: #3576ff;
        }
      }
    }
    .process {
      position: relative;
      &:after {
        @include u-tail;
      }
      .m-steps-icon {
        // background: $themeColor;
        border: 2px solid $themeColor;
        .u-icon {
          color: #fff;
        }
      }
      .m-steps-content {
        color: #3576ff;
        .u-steps-title {
          color: #3576ff;
        }
        .u-steps-description {
          color: #3576ff;
        }
      }
    }
    .last-process {
      .m-steps-icon {
        background: $themeColor;
        border: 2px solid rgba(0, 0, 0, 0.25);
        border-color: $themeColor;
        .u-icon {
          color: #fff;
        }
      }
      .m-steps-content {
        color: #3576ff;

        .u-steps-title {
          color: #3576ff;
        }
        .u-steps-description {
          color: #3576ff;
        }
      }
    }
    .middle-wait {
      position: relative;
    //   &:hover {
    //     .m-steps-icon {
    //       border: 2px solid $themeColor;
    //       .u-icon {
    //         color: $themeColor;
    //       }
    //     }
    //     .m-steps-content {
    //       .u-steps-title {
    //         color: $themeColor;
    //       }
    //       .u-steps-description {
    //         color: $themeColor;
    //       }
    //     }
    //   }
      &:after {
        @include u-tail;
      }
      .m-steps-icon {
        background: #fff;
        border: 2px solid rgba(0, 0, 0, 0.25);
        .u-icon {
          color: rgba(0, 0, 0, 0.25);
        }
      }
      .m-steps-content {
        color: rgba(0, 0, 0, 0.65);
        .u-steps-title {
          color: rgba(0, 0, 0, 0.45);
        }
        .u-steps-description {
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
    .last-wait {
      margin-right: 0;
    //   &:hover {
    //     .m-steps-icon {
    //       border: 2px solid $themeColor;
    //       .u-icon {
    //         color: $themeColor;
    //       }
    //     }
    //     .m-steps-content {
    //       .u-steps-title {
    //         color: $themeColor;
    //       }
    //       .u-steps-description {
    //         color: $themeColor;
    //       }
    //     }
    //   }
      .m-steps-icon {
        background: #fff;
        border: 2px solid rgba(0, 0, 0, 0.25);
        .u-icon {
          color: rgba(0, 0, 0, 0.25);
        }
      }
      .m-steps-content {
        color: rgba(0, 0, 0, 0.65);
        .u-steps-title {
          color: rgba(0, 0, 0, 0.45);
        }
        .u-steps-description {
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }
}
</style>
