<template>
  <el-dialog title="修改签到状态" :visible.sync="openDialog" width="360px" :show-close="true"
    class="dialog-vertical" @close="cancel('signStatus')">
    <el-form size="small" :model="_data" ref="signStatus" label-width="85px" @submit.native.prevent>
      <el-form-item style="margin-bottom: 0px" label="签到状态：" prop="content">
        <el-radio v-model="signStatus" :label="0">未签到</el-radio>
        <el-radio v-model="signStatus" :label="1">已签到</el-radio>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button type="primary" size="small" @click="submit('signStatus')" :loading="saveLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { signJoinUser } from "../../api/sign-up-list/list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    sign_status: {
      type: Number,
    },
    id: {
      type: [Number, String],
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      signStatus: 0,
      saveLoading: false,
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          signJoinUser({ id: this.id, sign_time: this.signStatus })
            .then((res) => {
              this.$message.success(res.msg);
              this.saveLoading = false;
              this.openDialog = false;
              this.updateList();
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.signStatus = this.sign_status;
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
